import { FC, useCallback, useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAward,
  faFlask,
  faMagnifyingGlass,
  faRainbow,
} from "@fortawesome/pro-solid-svg-icons";
import {
  NameYourQueryModal,
  QueryDetailsProvidedLoader,
} from "Components/Queries";
import { WizardOption } from "./WizardOption";
import { IQueryDTO } from "Interfaces";
import { ToastHelperSingleton } from "Helpers";
import { WindowingContext } from "Providers";
import { QueryControllerSingleton } from "Controllers";
import { QueryConstants } from "Constants";
import { LogFeatureNameEnum, SearchQueryTypeEnum, ToastTypeEnum } from "Enums";
import styles from "./createQueryWizard.module.scss";

type TCreateQueryWizardProps = {
  onCreationDone?: () => void;
  isSimpleMode?: boolean;
  userEmail: string;
};

export const CreateQueryWizard: FC<TCreateQueryWizardProps> = ({
  onCreationDone,
  isSimpleMode,
  userEmail,
}: TCreateQueryWizardProps) => {
  const { addSearchWindow, deleteWindow, updateQueryWindowState } =
    useContext(WindowingContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedQueryType, setSelectedQueryType] =
    useState<SearchQueryTypeEnum>(SearchQueryTypeEnum.USEOnScience);

  const optionTitleMap = {
    Technologies: QueryConstants.SEARCH_FOR_TECHNOLOGIES,
    "Scientific papers": QueryConstants.SEARCH_FOR_SCIENTIFIC_PAPERS,
    Patents: QueryConstants.SEARCH_FOR_PATENTS,
  };

  const onOptionClick = (searchQueryType: SearchQueryTypeEnum) => {
    setSelectedQueryType(searchQueryType);
    setIsModalOpen(true);
  };

  const openQueryDetails = useCallback(
    async (query: IQueryDTO, actionOrigin: string) => {
      addSearchWindow(
        query,
        <QueryDetailsProvidedLoader
          queryId={query.guid}
          onDelete={() => {
            deleteWindow(query.guid);
          }}
          onDuplicateAsync={async (duplicateQuery: IQueryDTO) => {
            // delete the window
            deleteWindow(query.guid);

            // open the duplicate query
            openQueryDetails(duplicateQuery, "DuplicateButton");
          }}
          onSearchStarted={(
            correlationId: string,
            type: SearchQueryTypeEnum,
            isTechnologySearchOnResults?: boolean | null,
            abortController?: AbortController
          ) => {
            updateQueryWindowState(query.guid, {
              correlationId,
              type,
              isTechnologySearchOnResults,
              abortController,
            });
          }}
          onSearchCompleted={(isTechnologySearchOnResults?: boolean | null) => {
            updateQueryWindowState(query.guid, {
              correlationId: undefined,
              type: undefined,
              isTechnologySearchOnResults,
              abortController: undefined,
            });
          }}
        />,
        actionOrigin
      );
    },
    [addSearchWindow, deleteWindow, updateQueryWindowState]
  );

  const onCreateQueryClickAsync = useCallback(
    async (
      currentIsCreateQueryButtonDisabled: boolean,
      currentQueryName: string,
      searchQueryType: SearchQueryTypeEnum = SearchQueryTypeEnum.USEOnScience
    ) => {
      if (currentIsCreateQueryButtonDisabled || !currentQueryName) {
        return;
      }

      const createdQuery: IQueryDTO | undefined =
        await QueryControllerSingleton.createAsync(
          currentQueryName,
          searchQueryType,
          LogFeatureNameEnum.CreateQueryModal
        );

      if (!createdQuery) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Failed to create query."
        );
        return;
      }

      openQueryDetails(createdQuery, LogFeatureNameEnum.CreateQueryModal);

      setIsModalOpen(false);

      if (onCreationDone) {
        onCreationDone();
      }
    },
    [onCreationDone, openQueryDetails]
  );

  const optionTitles = isSimpleMode
    ? Object.keys(optionTitleMap)
    : Object.values(optionTitleMap);

  return (
    <>
      <div
        className={`${styles.createQueryWizard} ${
          isSimpleMode ? styles.simpleMode : styles.normalMode
        }`}
      >
        {!isSimpleMode ? (
          <h3 className={styles.wizardHeader}>
            <FontAwesomeIcon icon={faMagnifyingGlass} />
            Start a new query
          </h3>
        ) : (
          <h6 className={styles.simpleWizardHeader}>I&apos;m looking for</h6>
        )}
        <div className={styles.wizardsContainer}>
          <WizardOption
            icon={faRainbow}
            title={optionTitles[0]}
            onClick={() => {
              onOptionClick(SearchQueryTypeEnum.UniverseTechnologies);
            }}
          />
          <WizardOption
            icon={faFlask}
            title={optionTitles[1]}
            onClick={() => {
              onOptionClick(SearchQueryTypeEnum.USEOnScience);
            }}
          />
          <WizardOption
            icon={faAward}
            title={optionTitles[2]}
            onClick={() => {
              onOptionClick(SearchQueryTypeEnum.USEOnPatents);
            }}
          />
        </div>
      </div>
      {isModalOpen && (
        <NameYourQueryModal
          isOpen={isModalOpen}
          onCreateQueryClickAsync={onCreateQueryClickAsync}
          closeModal={() => setIsModalOpen(false)}
          selectedQueryType={selectedQueryType}
          userEmail={userEmail}
        />
      )}
    </>
  );
};
