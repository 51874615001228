// node_modules
import { faCalendarAlt, faFile } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
// Enums
import {
  PatentDateTypeEnum,
  ScienceArticleDocumentTypeEnum,
  SearchQueryTypeEnum,
  ToastTypeEnum,
} from "Enums";
// Components
import { Checkbox, DivDropdown, FindestTextBox } from "Components";
import { Affiliations } from "./Affiliations";
import { Countries } from "./Countries";
// Styles
import styles from "./queryFilters.module.scss";
// Helpers
import { DateHelperSingleton, ToastHelperSingleton } from "Helpers";
// Interfaces
import { IQueryDTO } from "Interfaces";
// Controllers
import { QueryControllerSingleton } from "Controllers";
// Types
import { TQueryFiltersDTO } from "Types";

type TQueryFiltersProps = {
  query: IQueryDTO;
  setQuery: Dispatch<SetStateAction<IQueryDTO | undefined>>;
  searchQueryType: SearchQueryTypeEnum;
  advancedSearch?: boolean;
};

export const QueryFilters: FC<TQueryFiltersProps> = ({
  query,
  setQuery,
  searchQueryType,
  advancedSearch,
}: TQueryFiltersProps) => {
  // Memo
  const patentDateTypes = useMemo(() => {
    return ["publication", "filing"];
  }, []);

  // State
  const [selectedPatentDateType, setSelectedPatentDateType] = useState(
    patentDateTypes[
      query?.filters?.patentFilters?.patentDateType ===
      PatentDateTypeEnum.Filing
        ? 1
        : 0
    ]
  );
  const [queryFilters, setQueryFilters] = useState<TQueryFiltersDTO>(
    query?.filters ?? {}
  );

  // Logic
  useEffect(() => {
    setSelectedPatentDateType(
      patentDateTypes[
        query?.filters?.patentFilters?.patentDateType ===
        PatentDateTypeEnum.Filing
          ? 1
          : 0
      ]
    );
  }, [patentDateTypes, query]);

  const updateQueryFilters = useCallback(
    async (newFilters: TQueryFiltersDTO) => {
      // set query
      setQuery((prevQuery: IQueryDTO | undefined) => {
        // safety-checks
        if (!prevQuery) {
          // do nothing
          return prevQuery;
        }

        return {
          ...prevQuery,
          filters: newFilters,
        };
      });
    },
    [setQuery]
  );

  // Science document type handler
  const onScienceDocumentTypesEnabledChange = useCallback(
    async (isChecked: boolean, value: string) => {
      // safety-checks
      if (!query.filters || !query.filters.scienceFilters) {
        // do nothing
        return;
      }

      // get current science document types enabled
      const currentScienceDocumentTypesEnabled = {
        ...query.filters.scienceFilters.documentTypesEnabled,
      };

      // update query
      currentScienceDocumentTypesEnabled[parseInt(value)] = isChecked;
      query.filters.scienceFilters.documentTypesEnabled =
        currentScienceDocumentTypesEnabled;

      // call back-end to update filters
      const isSuccess: boolean =
        await QueryControllerSingleton.updateFiltersAsync(
          query.guid,
          query.filters
        );

      // safety-checks
      if (!isSuccess) {
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not update filters."
        );
        // stop execution
        return;
      }

      // update query filters
      updateQueryFilters(query.filters);
    },
    [query.filters, query.guid, updateQueryFilters]
  );

  const onPatentDateTypeChange = useCallback(
    async (newPatentDateType?: string) => {
      // safety-checks
      if (
        !query.filters ||
        !query.filters.patentFilters ||
        !newPatentDateType
      ) {
        // do nothing
        return;
      }

      // set selected date type
      setSelectedPatentDateType(newPatentDateType);

      // update query
      query.filters.patentFilters.patentDateType =
        patentDateTypes.indexOf(newPatentDateType);
      if (query.filters.patentFilters.patentDateType > 0) {
        query.filters.patentFilters.patentDateType++;
      }

      // call back-end to update filters
      const isSuccess: boolean =
        await QueryControllerSingleton.updateFiltersAsync(
          query.guid,
          query.filters
        );

      // safety-checks
      if (!isSuccess) {
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not update filters."
        );
        // stop execution
        return;
      }

      // update query filters
      updateQueryFilters(query.filters);
    },
    [patentDateTypes, query.filters, query.guid, updateQueryFilters]
  );

  const onChangeStartDate = useCallback(
    async (newStartDate: string) => {
      // safety-checks
      if (!query.filters) {
        // do nothing
        return;
      }

      // if search query type is universe science articles and science filters is defined
      if (
        searchQueryType === SearchQueryTypeEnum.UniverseScienceArticles &&
        query.filters.scienceFilters
      ) {
        // update start date
        query.filters.scienceFilters.startDate = newStartDate;
      } else if (
        searchQueryType === SearchQueryTypeEnum.UniversePatents &&
        query.filters.patentFilters
      ) {
        // otherwise if search query type is universe patents and patent filters is defined
        // update start date
        query.filters.patentFilters.startDate = newStartDate;
      }

      // call back-end to update filters
      const isSuccess: boolean =
        await QueryControllerSingleton.updateFiltersAsync(
          query.guid,
          query.filters
        );

      // safety-checks
      if (!isSuccess) {
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not update filters."
        );
        // stop execution
        return;
      }

      // update query filters
      updateQueryFilters(query.filters);
    },
    [query.filters, query.guid, searchQueryType, updateQueryFilters]
  );

  const onChangeEndDate = useCallback(
    async (newEndDate: string) => {
      // safety-checks
      if (!query.filters) {
        // do nothing
        return;
      }

      // if search query type is universe science articles and science filters is defined
      if (
        searchQueryType === SearchQueryTypeEnum.UniverseScienceArticles &&
        query.filters.scienceFilters
      ) {
        // update end date
        query.filters.scienceFilters.endDate = newEndDate;
      } else if (
        searchQueryType === SearchQueryTypeEnum.UniversePatents &&
        query.filters.patentFilters
      ) {
        // otherwise if search query type is universe patents and patent filters is defined
        // update end date
        query.filters.patentFilters.endDate = newEndDate;
      }

      // call back-end to update filters
      const isSuccess: boolean =
        await QueryControllerSingleton.updateFiltersAsync(
          query.guid,
          query.filters
        );

      // safety-checks
      if (!isSuccess) {
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not update filters."
        );
        // stop execution
        return;
      }

      // update query filters
      updateQueryFilters(query.filters);
    },
    [query.filters, query.guid, searchQueryType, updateQueryFilters]
  );

  const dateOnBlurHandler = useCallback(
    async (isEndYear: boolean): Promise<void> => {
      // safety-checks
      if (!query.filters) {
        return;
      }

      // init date display format
      const dateDisplayFormat = "YYYY-MM-DD";

      // set date in query
      // if search query type is universe patents and patent filters are present
      if (
        searchQueryType === SearchQueryTypeEnum.UniversePatents &&
        query.filters.patentFilters
      ) {
        // if end year is true
        if (isEndYear) {
          // if end date is present
          if (query.filters.patentFilters.endDate) {
            // set end date
            query.filters.patentFilters.endDate =
              DateHelperSingleton.getDateInFormatFromString(
                query.filters.patentFilters.endDate,
                dateDisplayFormat
              );
          }
        } else {
          // otherwise if start date is present
          if (query.filters.patentFilters.startDate) {
            // set start date
            query.filters.patentFilters.startDate =
              DateHelperSingleton.getDateInFormatFromString(
                query.filters.patentFilters.startDate,
                dateDisplayFormat
              );
          }
        }
      } else if (
        searchQueryType === SearchQueryTypeEnum.UniverseScienceArticles &&
        query.filters.scienceFilters
      ) {
        // otherwise if search query type is universe science articles and science filters are present
        // if end year is true
        if (isEndYear) {
          // if end date is present
          if (query.filters.scienceFilters.endDate) {
            // set end date
            query.filters.scienceFilters.endDate =
              DateHelperSingleton.getDateInFormatFromString(
                query.filters.scienceFilters.endDate,
                dateDisplayFormat
              );
          }
        } else {
          // otherwise if start date is present
          if (query.filters.scienceFilters.startDate) {
            // set start date
            query.filters.scienceFilters.startDate =
              DateHelperSingleton.getDateInFormatFromString(
                query.filters.scienceFilters.startDate,
                dateDisplayFormat
              );
          }
        }
      } else {
        // otherwise, do nothing
        return;
      }

      // call back-end to update filters
      const isSuccess: boolean =
        await QueryControllerSingleton.updateFiltersAsync(
          query.guid,
          query.filters
        );

      // safety-checks
      if (!isSuccess) {
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not update filters."
        );
        // stop execution
        return;
      }

      // update query filters
      updateQueryFilters(query.filters);
    },
    [query.filters, query.guid, searchQueryType, updateQueryFilters]
  );

  const onChangeHasCitationScore = useCallback(
    async (hasCitationScore: boolean) => {
      if (searchQueryType !== SearchQueryTypeEnum.USEOnScience) {
        return;
      }

      query.filters.useOnScienceFilters.hasCitationScore = hasCitationScore;

      // call back-end to update filters
      const isSuccess: boolean =
        await QueryControllerSingleton.updateFiltersAsync(
          query.guid,
          query.filters
        );

      // safety-checks
      if (!isSuccess) {
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not update filters."
        );
        // stop execution
        return;
      }

      // update query filters
      updateQueryFilters(query.filters);
    },
    [query.filters, query.guid, searchQueryType, updateQueryFilters]
  );

  const renderDateRanges = (): ReactNode => {
    const filters =
      searchQueryType === SearchQueryTypeEnum.UniverseScienceArticles ||
      searchQueryType === SearchQueryTypeEnum.USEOnScience
        ? query?.filters?.scienceFilters
        : searchQueryType === SearchQueryTypeEnum.UniversePatents ||
          searchQueryType === SearchQueryTypeEnum.USEOnPatents
        ? query?.filters?.patentFilters
        : undefined;

    return (
      <div className={styles.dataListItem}>
        <div className={styles.rangeContainer}>
          <FindestTextBox
            placeholder="YYYY-MM-DD"
            onChange={onChangeStartDate}
            value={filters?.["startDate"] ?? undefined}
            onBlur={() => dateOnBlurHandler(true)}
          />
          <span>-</span>
          <FindestTextBox
            placeholder="YYYY-MM-DD"
            onChange={onChangeEndDate}
            value={filters?.["endDate"] ?? undefined}
            onBlur={() => dateOnBlurHandler(true)}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      {advancedSearch ? (
        <div className={styles.userFlowDataList}>
          <div className={styles.filterListItem}>
            <div className={styles.filterListItemTitle}>
              <FontAwesomeIcon icon={faCalendarAlt} />
              <h6>Date range</h6>
            </div>
            {searchQueryType === SearchQueryTypeEnum.UniversePatents ? (
              <div className={styles.dataListItem}>
                <DivDropdown
                  objectList={patentDateTypes}
                  newObjectSelected={onPatentDateTypeChange}
                  selectedObjectIndex={patentDateTypes.indexOf(
                    selectedPatentDateType
                  )}
                  className={styles.divDropdownFilter}
                  overridenStyle={styles}
                >
                  {patentDateTypes.map((patentDateType: string) => {
                    return (
                      <div
                        style={{ textTransform: "capitalize" }}
                        key={patentDateType}
                      >
                        <span>{patentDateType}</span>
                      </div>
                    );
                  })}
                </DivDropdown>
              </div>
            ) : null}
            {renderDateRanges()}
          </div>
          <Affiliations
            queryFilters={queryFilters}
            setQueryFilters={setQueryFilters}
            queryId={query.guid}
            searchQueryType={searchQueryType}
          />
          {searchQueryType === SearchQueryTypeEnum.UniversePatents ? (
            <Countries
              queryFilters={queryFilters}
              setQueryFilters={setQueryFilters}
              queryId={query.guid}
            />
          ) : null}
          {searchQueryType === SearchQueryTypeEnum.UniverseScienceArticles &&
          query.filters &&
          query.filters.scienceFilters &&
          query.filters.scienceFilters.documentTypesEnabled ? (
            <div className={styles.filterListItem}>
              <div className={styles.filterListItemTitle}>
                <FontAwesomeIcon icon={faFile} />
                <h6>Document type</h6>
              </div>
              <div className={styles.scienceDocumentTypeFilterContainer}>
                <div className={styles.scienceDocumentTypeFilter}>
                  <Checkbox
                    isChecked={
                      query.filters.scienceFilters.documentTypesEnabled[
                        ScienceArticleDocumentTypeEnum.Journal
                      ]
                    }
                    onCheckboxChange={(isChecked) => {
                      onScienceDocumentTypesEnabledChange(
                        isChecked,
                        ScienceArticleDocumentTypeEnum.Journal.toString()
                      );
                    }}
                    id="Journal"
                  />
                  <label htmlFor="Journal">Journal</label>
                </div>
                <div className={styles.scienceDocumentTypeFilter}>
                  <Checkbox
                    isChecked={
                      query.filters.scienceFilters.documentTypesEnabled[
                        ScienceArticleDocumentTypeEnum.Conference
                      ]
                    }
                    onCheckboxChange={(isChecked) => {
                      onScienceDocumentTypesEnabledChange(
                        isChecked,
                        ScienceArticleDocumentTypeEnum.Conference.toString()
                      );
                    }}
                    id="Conference"
                  />
                  <label htmlFor="Conference">Conference</label>
                </div>
                <div className={styles.scienceDocumentTypeFilter}>
                  <Checkbox
                    isChecked={
                      query.filters.scienceFilters.documentTypesEnabled[
                        ScienceArticleDocumentTypeEnum.Book
                      ]
                    }
                    onCheckboxChange={(isChecked) => {
                      onScienceDocumentTypesEnabledChange(
                        isChecked,
                        ScienceArticleDocumentTypeEnum.Book.toString()
                      );
                    }}
                    id="Book"
                  />
                  <label htmlFor="Book">Book</label>
                </div>
                <div className={styles.scienceDocumentTypeFilter}>
                  <Checkbox
                    isChecked={
                      query.filters.scienceFilters.documentTypesEnabled[
                        ScienceArticleDocumentTypeEnum.BookChapter
                      ]
                    }
                    onCheckboxChange={(isChecked) => {
                      onScienceDocumentTypesEnabledChange(
                        isChecked,
                        ScienceArticleDocumentTypeEnum.BookChapter.toString()
                      );
                    }}
                    id="Book Chapter"
                  />
                  <label htmlFor="Book Chapter">Book Chapter</label>
                </div>
                <div className={styles.scienceDocumentTypeFilter}>
                  <Checkbox
                    isChecked={
                      query.filters.scienceFilters.documentTypesEnabled[
                        ScienceArticleDocumentTypeEnum.Thesis
                      ]
                    }
                    onCheckboxChange={(isChecked) => {
                      onScienceDocumentTypesEnabledChange(
                        isChecked,
                        ScienceArticleDocumentTypeEnum.Thesis.toString()
                      );
                    }}
                    id="Thesis"
                  />
                  <label htmlFor="Thesis">Thesis</label>
                </div>
                <div className={styles.scienceDocumentTypeFilter}>
                  <Checkbox
                    isChecked={
                      query.filters.scienceFilters.documentTypesEnabled[
                        ScienceArticleDocumentTypeEnum.Repository
                      ]
                    }
                    onCheckboxChange={(isChecked) => {
                      onScienceDocumentTypesEnabledChange(
                        isChecked,
                        ScienceArticleDocumentTypeEnum.Repository.toString()
                      );
                    }}
                    id="Repository"
                  />
                  <label htmlFor="Repository">Repository</label>
                </div>
                <div className={styles.scienceDocumentTypeFilter}>
                  <Checkbox
                    isChecked={
                      query.filters.scienceFilters.documentTypesEnabled[
                        ScienceArticleDocumentTypeEnum.Other
                      ]
                    }
                    onCheckboxChange={(isChecked) => {
                      onScienceDocumentTypesEnabledChange(
                        isChecked,
                        ScienceArticleDocumentTypeEnum.Other.toString()
                      );
                    }}
                    id="Other"
                  />
                  <label htmlFor="Other">Other</label>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <div className={styles.userFlowDataList}>
          <div
            className={`${styles.filterListItem} ${styles.filterListItemPublicationYears}`}
          >
            <p className={styles.publicationYearsTitle}>Publication year</p>
            {renderDateRanges()}
          </div>
          {(searchQueryType === SearchQueryTypeEnum.USEOnScience || searchQueryType === SearchQueryTypeEnum.UniverseScienceArticles) && (
            <div
              className={`${styles.filterListItem} ${styles.filterListItemHasCitationScore}`}
            >
              <div className={styles.hasCitationScore}>
                <Checkbox
                  isChecked={query.filters.useOnScienceFilters.hasCitationScore}
                  onCheckboxChange={(isChecked) => {
                    onChangeHasCitationScore(isChecked);
                  }}
                  id="hasCitationScore "
                />
                <label htmlFor="hasCitationScore">Has citation score</label>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
