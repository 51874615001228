// node_modules
import axios, { AxiosResponse } from "axios";
import { ObjectTypeEnum, OrderByEnum, SavedDocumentTypeEnum } from "Enums";
// Helpers
import { AxiosHelperSingleton, ObjectTypeHelperSingleton } from "Helpers";
// Types
import { TAxiosParams, TDocumentsDTO, TInboxListDTO, TOption } from "Types";
// Interfaces
import { IDocumentDTO, ISavedDocumentDTO } from "Interfaces";

export class SavedDocumentController {
    private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/saveddocument`;
    private readonly _resourcePathv2 = `${AxiosHelperSingleton.getServerBaseURL()}api/v2/saved-objects`;
    private defaultTDocumentsDTOValue: TDocumentsDTO = {
        documents: [],
        totalCount: 0
    };

    private readonly defaultTDocumentsListDTOValue: TInboxListDTO = {
        items: [],
        pageItemCount: 0,
        isLastPage: false,
        totalItemCount: 0
    };
    public async getMyInboxAsync(
            orderBy: OrderByEnum,
            filterOptions: TOption<SavedDocumentTypeEnum>[],
            page: number
        ): Promise<TInboxListDTO> {
        let url = `${this._resourcePathv2}?orderBy=${orderBy}&skip=${page*1}&limit=25`;
        for (const filterOption of filterOptions) {
            if (filterOption.value === SavedDocumentTypeEnum.UsPatent || filterOption.value === SavedDocumentTypeEnum.MagPatent) {
                url += `&objectTypes=${SavedDocumentTypeEnum.MagPatent}&objectTypes=${SavedDocumentTypeEnum.UsPatent}`;
            } else if (filterOption.value === SavedDocumentTypeEnum.ScienceArticle) {
                url += `&objectTypes=${SavedDocumentTypeEnum.ScienceArticle}`;
            } else if (filterOption.value === SavedDocumentTypeEnum.Weblink) {
                url += `&objectTypes=${SavedDocumentTypeEnum.Weblink}`;
            }
        }
        try {
            const response: AxiosResponse<TInboxListDTO> = await axios
                .get<TInboxListDTO>(url);
            if (response?.data) {
                return response.data;
            } else {
                return this.defaultTDocumentsListDTOValue;
            }
        } catch {
            return this.defaultTDocumentsListDTOValue;
        }
    }

    public async getMyInboxCountAsync(): Promise<number> {
        try {
            const response: AxiosResponse<TInboxListDTO> = await axios
                .get<TInboxListDTO>(`${this._resourcePathv2}?limit=100`);
            if (response?.data) {
                return response.data.items.length;
            } else {
                return 0;
            }
        } catch {
            return 0;
        }
    }

    public async getMyAsync(orderBy: OrderByEnum,
            fromDate: Date | undefined, filterOptions: TOption<SavedDocumentTypeEnum>[], 
            doExcludeLinked: boolean): Promise<TDocumentsDTO> {
        let doIncludePatents = filterOptions.length === 0;
        let doIncludeScienceArticles = filterOptions.length === 0;
        let doIncludeWeblinks = filterOptions.length === 0;
        for (const filterOption of filterOptions) {
            if (filterOption.value === SavedDocumentTypeEnum.UsPatent || 
                    filterOption.value === SavedDocumentTypeEnum.MagPatent) {
                doIncludePatents = true;
            } else if (filterOption.value === SavedDocumentTypeEnum.ScienceArticle) {
                doIncludeScienceArticles = true;
            } else if (filterOption.value === SavedDocumentTypeEnum.Weblink) {
                doIncludeWeblinks = true;
            }
        }

        let url = `${this._resourcePath}/my?orderBy=${orderBy}&doIncludePatents=${doIncludePatents}&doIncludeScienceArticles=${doIncludeScienceArticles}&doIncludeWeblinks=${doIncludeWeblinks}&doExcludeLinked=${doExcludeLinked}`;
        if (fromDate) {
            url += `&fromDate=${fromDate}`;
        }

        try {
            const response: AxiosResponse<TDocumentsDTO> = await axios
                .get<TDocumentsDTO>(url);

            if (response && response.data) {
                return response.data;
            } else {
                return this.defaultTDocumentsDTOValue;
            }
        } catch {
            return this.defaultTDocumentsDTOValue;
        }
    }

    public async getMyCountAsync(doExcludeLinked: boolean): Promise<number> {
        const url = `${this._resourcePath}/my/count?doExcludeLinked=${doExcludeLinked}`;
        try {
            const response: AxiosResponse<number> = await axios
                .get<number>(url);

            if (response && response.data) {
                return response.data;
            } else {
                return 0;
            }
        } catch {
            return 0;
        }
    }

    public async getObjectSavedDocumentsAsync(objectId: string, objectType: ObjectTypeEnum,
            orderBy: OrderByEnum, fromDate: Date | undefined, 
            filterOptions: TOption<SavedDocumentTypeEnum>[]): Promise<TDocumentsDTO> {
        const startUrl = `${AxiosHelperSingleton.getServerBaseURL()}api/${ObjectTypeHelperSingleton.getObjectTypeEndpointName(objectType).toLowerCase()}`;
        
        let doIncludePatents = filterOptions.length === 0;
        let doIncludeScienceArticles = filterOptions.length === 0;
        let doIncludeWeblinks = filterOptions.length === 0;
        for (const filterOption of filterOptions) {
            if (filterOption.value === SavedDocumentTypeEnum.UsPatent || 
                    filterOption.value === SavedDocumentTypeEnum.MagPatent) {
                doIncludePatents = true;
            } else if (filterOption.value === SavedDocumentTypeEnum.ScienceArticle) {
                doIncludeScienceArticles = true;
            } else if (filterOption.value === SavedDocumentTypeEnum.Weblink) {
                doIncludeWeblinks = true;
            }
        }
        
        try {
            const url = `${startUrl}/${objectId}/saveddocuments`;
            const axiosParams: TAxiosParams = {
                orderBy: orderBy,
                doIncludePatents: doIncludePatents,
                doIncludeScienceArticles: doIncludeScienceArticles,
                doIncludeWeblinks: doIncludeWeblinks
            };
            if (fromDate) {
                axiosParams.fromDate = fromDate;
            }

            const response = await axios.get<TDocumentsDTO>(url, {
                params: axiosParams
            });

            if (response && response.data) {
                return response.data;
            } else {
                return this.defaultTDocumentsDTOValue;
            }
        } catch {
            return this.defaultTDocumentsDTOValue;
        }
    }

    public async getAsync(orderBy: OrderByEnum,
        fromDate: Date | undefined, filterOptions: TOption<SavedDocumentTypeEnum>[]): Promise<TDocumentsDTO> {
        let doIncludePatents = filterOptions.length === 0;
        let doIncludeScienceArticles = filterOptions.length === 0;
        let doIncludeWeblinks = filterOptions.length === 0;
        for (const filterOption of filterOptions) {
            if (filterOption.value === SavedDocumentTypeEnum.UsPatent || 
                    filterOption.value === SavedDocumentTypeEnum.MagPatent) {
                doIncludePatents = true;
            } else if (filterOption.value === SavedDocumentTypeEnum.ScienceArticle) {
                doIncludeScienceArticles = true;
            } else if (filterOption.value === SavedDocumentTypeEnum.Weblink) {
                doIncludeWeblinks = true;
            }
        }

        let url = `${this._resourcePath}?orderBy=${orderBy}&doIncludePatents=${doIncludePatents}&doIncludeScienceArticles=${doIncludeScienceArticles}&doIncludeWeblinks=${doIncludeWeblinks}`;
        if (fromDate) {
            url += `&fromDate=${fromDate}`;
        }

        try {
            const response: AxiosResponse<TDocumentsDTO> = await axios
                .get<TDocumentsDTO>(url);

            if (response && response.data) {
                return response.data;
            } else {
                return this.defaultTDocumentsDTOValue;
            }
        } catch {
            return this.defaultTDocumentsDTOValue;
        }
    }

    public async getLinkedToObject(objectId: string): Promise<ISavedDocumentDTO[] | undefined> {
        try {
            const response = await axios.get<ISavedDocumentDTO[]>(`${this._resourcePath}/linkedto/${objectId}`);

            if (response && response.data) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async getByIdAsync(documentId: string): Promise<ISavedDocumentDTO | undefined> {
        try {
            // get document by id
            const response = await axios.get<ISavedDocumentDTO>(`${this._resourcePath}/${documentId}`);

            // deal with response
            if (response && response.data) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async getByIdAsyncV2(documentId: string): Promise<IDocumentDTO | undefined> {
        try {
            // get document by id
            const response = await axios.get<IDocumentDTO>(`${this._resourcePathv2}/${documentId}`);
            // deal with response
            if (response?.data) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }
}

export const SavedDocumentControllerSingleton = new SavedDocumentController();
