// node_modules
import { Editor, JSONContent } from "@tiptap/react";
import { FC, useCallback, useContext, useEffect, useState } from "react";
// Controllers
import { ReadDocumentsControllerSingleton } from "Controllers";
// Enums
import {
  ObjectTypeEnum,
  QuerySortOptionsEnum,
  SearchQueryTypeEnum,
  ToastTypeEnum,
} from "Enums";
// Helpers
import {
  ExtensionsKit,
  GetCustomLink,
  LogHelperSingleton,
  ObjectTypeHelperSingleton,
  ToastHelperSingleton,
} from "Helpers";
// Hooks
import { useDocument, useEntity, useReference, useStudy } from "Hooks";
// Interfaces
import {
  fromIDocumentSearchResultTOISavedDocumentDTO,
  IDocumentSearchResult,
  IQueryDTO,
} from "Interfaces";
// Providers
import { QueryViewOptionsContext } from "Providers";
// Types
import { TIdNameTypeObjectType } from "Types";
// Components
import { ReferenceCard } from "Components";
import { QuerySaveResults } from "Components/Queries/QuerySaveResults";
import { EditorPopover } from "Components/Shared/Editor/EditorPopover/EditorPopover";
import { DocumentSearchResult } from "./SearchResults";
// Icons
import {
  faCaretDown,
  faCaretUp,
  faRobot,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Styles
import styles from "./queryAnswerResult.module.scss";

interface IQueryAnswerResult {
  useChatResponse: string;
  query: IQueryDTO;
  onAddUSEResultsOrder?: (order: { id: string; order: number }[]) => void;
  sortOption?: QuerySortOptionsEnum;
  onGetUseChatResponseDocuments: (documents: IDocumentSearchResult[]) => void;
}

export const QueryAnswerResult: FC<IQueryAnswerResult> = ({
  useChatResponse,
  query,
  onAddUSEResultsOrder,
  sortOption,
  onGetUseChatResponseDocuments,
}) => {
  // State
  const [showNLQDocuments, setShowNLQDocuments] = useState<boolean>(false);
  const [documentsSelected, setDocumentsSelected] = useState<
    IDocumentSearchResult[]
  >([]);
  const [documentsFromUseChatResponse, setDocumentsFromUseChatResponse] =
    useState<IDocumentSearchResult[]>([]);
  const [useChatResponseFormatted, setUseChatResponseFormatted] =
    useState<string>("");
  const [referenceNumber, setReferenceNumber] = useState<
    { id: string; order: number }[]
  >([]);
  const [referenceClickId, setReferenceClickId] =
    useState<string | undefined>(undefined);
  const [answerContentRef, setAnswerContentRef] =
    useState<HTMLDivElement | null>(null);

  // Hooks
  const {
    loadIsAlreadyReadOnDocumentResultsAsync,
    loadDocumentSearchResultMetadataAsync,
    sortDocuments,
  } = useDocument();
  const { appendContentToEntityDescriptionAsync } = useEntity();
  const { appendContentToStudyDescriptionAsync } = useStudy();
  const { referenceElement, referenceLink, referenceId, resetReference } =
    useReference({
      ref: answerContentRef,
      click: true,
    });

  // Context
  const { allQueryViewOptions } = useContext(QueryViewOptionsContext);

  // Logic
  useEffect(() => {
    (async () => {
      if (!useChatResponse) {
        setUseChatResponseFormatted("");
        setDocumentsFromUseChatResponse([]);
        setDocumentsSelected([]);
        onGetUseChatResponseDocuments([]);
        return;
      }

      const documentNumberPerId: { [key: string]: string } =
        extractDocumentIdsFromUseChatResponse(useChatResponse);
      const documentIds = Object.keys(documentNumberPerId);

      const documentReferenceNumbers = documentIds.reduce<
        { id: string; order: number }[]
      >((acc, id, index) => {
        if (!documentIds.slice(0, index).includes(id)) {
          acc.push({ id, order: acc.length + 1 });
        }
        return acc;
      }, []);

      setReferenceNumber(documentReferenceNumbers);
      onAddUSEResultsOrder?.(documentReferenceNumbers);

      let newDocumentsFromUseChatResponse: IDocumentSearchResult[] =
        await loadDocumentSearchResultMetadataAsync(
          documentIds.map(
            (documentId) =>
              ({
                documentId,
              } as IDocumentSearchResult)
          )
        );

      newDocumentsFromUseChatResponse =
        await loadIsAlreadyReadOnDocumentResultsAsync(
          newDocumentsFromUseChatResponse,
          await ReadDocumentsControllerSingleton.getMyAsync()
        );

      setUseChatResponseFormatted(
        replaceSpansWithLinks(
          useChatResponse,
          newDocumentsFromUseChatResponse,
          documentNumberPerId
        )
      );

      setDocumentsFromUseChatResponse(newDocumentsFromUseChatResponse);
      onGetUseChatResponseDocuments(newDocumentsFromUseChatResponse);

      setDocumentsSelected(newDocumentsFromUseChatResponse);
    })();
  }, [
    loadDocumentSearchResultMetadataAsync,
    loadIsAlreadyReadOnDocumentResultsAsync,
    onAddUSEResultsOrder,
    onGetUseChatResponseDocuments,
    useChatResponse,
  ]);

  const extractDocumentIdsFromUseChatResponse = (
    currUseChatResponse: string
  ): { [key: string]: string } => {
    const documentNumberPerId: { [key: string]: string } = {};
    const regex = /<span id="([^"]+)"[^>]*>(\d+)<\/span>/g;

    let match;
    while ((match = regex.exec(currUseChatResponse)) !== null) {
      if (!documentNumberPerId[match[1]]) {
        documentNumberPerId[match[1]] = match[2];
      }
    }

    return documentNumberPerId;
  };

  const replaceSpansWithLinks = (
    currUseChatResponse: string,
    currDocumentsFromUseChatResponse: IDocumentSearchResult[],
    documentNumberPerId: { [key: string]: string }
  ): string => {
    const regex = /\[([^[\]]*<span[^>]*>[^<]*<\/span>[^[\]]*)\]/g;

    let transformedString = currUseChatResponse;

    let match;
    while ((match = regex.exec(currUseChatResponse)) !== null) {
      const fullMatch = match[0];

      const domParser = new DOMParser();
      const doc = domParser.parseFromString(fullMatch, "text/html");
      const spanElement = doc.querySelector("span");

      const documentId = spanElement?.getAttribute("id");
      const title = spanElement?.getAttribute("title");
      const number = spanElement?.textContent;

      if (documentId && title && number) {
        const document = currDocumentsFromUseChatResponse.find(
          (currDoc) => currDoc.documentId === documentId.trim()
        );

        if (document) {
          transformedString = transformedString.replace(
            fullMatch,
            `<a target="_blank" rel="noopener noreferrer" href="${
              document.url
            }" id="${documentId}" title="${
              document.title
            }" type="${ObjectTypeHelperSingleton.documentTypeToObjectType(
              document.documentType
            )}">[${documentNumberPerId[document.documentId]}]</a>`
          );
        } else {
          transformedString = transformedString.replace(
            fullMatch,
            `<a target="_blank" rel="noopener noreferrer" id="${documentId}" title="${title}">[${number}]</a>`
          );
        }
      }
    }

    return transformedString;
  };

  const onAddToSelected = (document: IDocumentSearchResult): void => {
    if (
      documentsSelected.some((doc) => doc.documentId === document.documentId)
    ) {
      setDocumentsSelected((prev) =>
        prev.filter((doc) => doc.documentId !== document.documentId)
      );
    } else {
      setDocumentsSelected((prev) => [...prev, document]);
    }
  };

  const updateDocument = (document: IDocumentSearchResult): void => {
    setDocumentsSelected((prev) =>
      prev.map((doc) =>
        doc.documentId === document.documentId ? document : doc
      )
    );

    setDocumentsFromUseChatResponse((prev) =>
      prev.map((doc) =>
        doc.documentId === document.documentId ? document : doc
      )
    );
  };

  const onDocumentsLinkedAsync = async (
    toObject: TIdNameTypeObjectType
  ): Promise<void> => {
    const errorMessage = `Could not append answer to ${ObjectTypeHelperSingleton.getObjectTypeDisplayName(
      toObject.objectType
    ).toLowerCase()} description.`;

    const editor = new Editor({
      extensions: [...ExtensionsKit, GetCustomLink()],
      content: useChatResponseFormatted,
    });

    const contentToAppendToObjectDescription: JSONContent[] =
      editor.getJSON().content ?? [];
    if (
      !contentToAppendToObjectDescription ||
      contentToAppendToObjectDescription.length === 0
    ) {
      ToastHelperSingleton.showToast(ToastTypeEnum.Error, errorMessage);
      return;
    }

    let isSuccess = false;

    if (toObject.objectType === ObjectTypeEnum.Entity) {
      isSuccess = await appendContentToEntityDescriptionAsync(
        toObject.id,
        contentToAppendToObjectDescription
      );
    } else if (toObject.objectType === ObjectTypeEnum.Study) {
      isSuccess = await appendContentToStudyDescriptionAsync(
        toObject.id,
        contentToAppendToObjectDescription
      );
    }

    if (isSuccess) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Success,
        `Answer appended to ${ObjectTypeHelperSingleton.getObjectTypeDisplayName(
          toObject.objectType
        ).toLowerCase()} description.`
      );

      LogHelperSingleton.log(
        `AppendedNLQOn${
          query.type === SearchQueryTypeEnum.USEOnPatents
            ? "Patents"
            : "Science"
        }AnswerToDescription`
      );
    } else {
      ToastHelperSingleton.showToast(ToastTypeEnum.Error, errorMessage);
    }
  };

  const sortDocumentsByOption = useCallback(() => {
    if (sortOption) {
      setDocumentsFromUseChatResponse(
        sortDocuments(sortOption, documentsFromUseChatResponse)
      );
    }
  }, [sortOption, documentsFromUseChatResponse, sortDocuments]);

  useEffect(() => {
    sortDocumentsByOption();
  }, [sortDocumentsByOption, sortOption]);

  useEffect(() => {
    setReferenceClickId(referenceId);
  }, [referenceId]);

  return (
    <>
      {useChatResponseFormatted.length ? (
        <div className={styles.querySearchResultsAnswerContainer}>
          <h4 className={styles.querySearchResultsAnswerTitle}>Quick answer</h4>
          <div className={styles.querySearchResultsAnswerContent}>
            <div className={styles.querySearchResultsIcon}>
              <FontAwesomeIcon icon={faRobot} />
            </div>
            <div className={styles.querySearchResultsAnswerBox}>
              <p
                ref={setAnswerContentRef}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: useChatResponseFormatted }}
              ></p>
              <button
                type="button"
                onClick={() => {
                  setShowNLQDocuments(!showNLQDocuments);
                }}
              >
                Sources mentioned in this answer
                <FontAwesomeIcon
                  icon={showNLQDocuments ? faCaretUp : faCaretDown}
                  className={styles.chevronIcon}
                />
              </button>
              {documentsFromUseChatResponse.length > 0 && (
                <div
                  className={`${styles.querySearchResultsContent} ${
                    !showNLQDocuments ? styles.hideContent : ""
                  }`}
                >
                  <div className={styles.querySearchResultsDocuments}>
                    {documentsFromUseChatResponse.map((document) => {
                      return (
                        <DocumentSearchResult
                          checked={documentsSelected.some(
                            (selectedDoc) =>
                              selectedDoc.documentId === document.documentId
                          )}
                          key={document.documentId}
                          document={document}
                          doIncludeCheckbox={true}
                          updateDocument={updateDocument}
                          queryViewOptions={allQueryViewOptions}
                          onAddToSelected={onAddToSelected}
                          orderNumber={
                            referenceNumber.find(
                              (linkReferenceNumber) =>
                                linkReferenceNumber.id === document.documentId
                            )?.order
                          }
                          documentModal={
                            referenceClickId === document.documentId
                              ? document.documentId
                              : undefined
                          }
                        />
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            <QuerySaveResults
              documentsSelected={documentsSelected}
              filteredDocumentResults={documentsFromUseChatResponse}
              query={query}
              documentCount={documentsFromUseChatResponse.length}
              hasAnswer={true}
              onDocumentsLinkedAsync={onDocumentsLinkedAsync}
            />
            <EditorPopover
              closePopover={resetReference}
              isOpen={!!(referenceElement && referenceLink)}
              refElement={referenceElement}
              extraClassNames={{ popover: styles.referenceCard }}
            >
              <ReferenceCard
                id={referenceLink?.id}
                type={referenceLink?.objectType}
                url={referenceLink?.name}
                onClickCallback={resetReference}
                savedDocuments={documentsFromUseChatResponse.map(
                  fromIDocumentSearchResultTOISavedDocumentDTO
                )}
              />
            </EditorPopover>
          </div>
        </div>
      ) : null}
    </>
  );
};
