// Enums
import { ObjectTypeEnum } from "Enums";
import { IEntityDTO, IStudyDTO } from "Interfaces";
// Types
import {
  TLinkGraphNodeDTO,
  TRecentSidebarActivityItemDTO,
  TReferenceByDTO,
  TSidebarListItem,
} from "Types";

export type TIdNameTypeObjectType = {
  id: string;
  name?: string;
  type: string;
  customTypeName?: string;
  url?: string;
  objectType: ObjectTypeEnum;
  isConnected?: boolean;
  description?: string;
  createdByUserId?: string;
  dateAdded?: Date;
  title?: string;
  date?: Date;
};

export const fromTLinkGraphNodeDTO = (
  linkGraphNode: TLinkGraphNodeDTO
): TIdNameTypeObjectType => {
  return {
    id: linkGraphNode.id,
    name: linkGraphNode.name,
    type: linkGraphNode.type,
    customTypeName: linkGraphNode.customTypeName,
    objectType: linkGraphNode.objectType,
  };
};

export const fromTRecentSidebarActivityItemDTO = (
  recentSidebarActivityItem: TRecentSidebarActivityItemDTO
): TIdNameTypeObjectType => {
  return {
    id: recentSidebarActivityItem.id,
    name: recentSidebarActivityItem.name,
    type: recentSidebarActivityItem.fullType,
    customTypeName: recentSidebarActivityItem.customType,
    objectType: recentSidebarActivityItem.objectType,
    date: recentSidebarActivityItem.date
  };
};
export const fromTSidebarListItem = (
  sidebarListItem: TSidebarListItem
): TIdNameTypeObjectType => {
  let objectType = ObjectTypeEnum.Unknown;
  if (sidebarListItem.navigateTo?.includes("entities/")) {
    objectType = ObjectTypeEnum.Entity;
  } else if (sidebarListItem.navigateTo?.includes("studies/")) {
    objectType = ObjectTypeEnum.Study;
  }

  return {
    id: sidebarListItem.id ?? "",
    name: sidebarListItem.title ?? "",
    type: "",
    customTypeName: "",
    objectType,
  };
};

export const fromIEntityDTO = (entity: IEntityDTO): TIdNameTypeObjectType => {
  return {
    id: entity.id ?? "",
    name: entity.title,
    type: "",
    customTypeName: "",
    objectType: ObjectTypeEnum.Entity,
  };
};

export const fromIStudyDTO = (study: IStudyDTO): TIdNameTypeObjectType => {
  return {
    id: study.id ?? "",
    name: study.title,
    type: "",
    customTypeName: "",
    objectType: ObjectTypeEnum.Study,
  };
};

export const fromTReferenceByDTO = (
  referenceBy: TReferenceByDTO
): TIdNameTypeObjectType => {
  return {
    id: referenceBy.id,
    name: referenceBy.title,
    type: "",
    customTypeName: "",
    objectType: referenceBy.type,
  };
};
