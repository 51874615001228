// React
import { FC, useMemo, useState } from "react";
import { faFile, faGlobe } from "@fortawesome/pro-solid-svg-icons";
// Types
import { TAskIgorRequirement, TIdNameTypeObjectType, TLineChat } from "Types";
// Components
import { TextLineChat } from "../../../Chat/TextLineChat/TextLineChat";
import { AskIgorRequirementsLineChat } from "./AskIgorRequirementsLineChat";
import { AskIgorSettings } from "./AskIgorSettings";
// Styles
import styles from "./askIgorLineChats.module.scss";
// Enums
import { AskIgorMenuItemEnum, ObjectTypeEnum } from "Enums";

type TAskIgorLineChatsProps = {
  object: TIdNameTypeObjectType;
  selectedMenuItem: AskIgorMenuItemEnum | undefined;
  updateSelectedMenuItem: (newSelectedMenuItem: AskIgorMenuItemEnum) => void;
  isGeneratingText: boolean;
  defaultInput?: string;
  onSubmit: (
    text: string,
    documentTypes: ObjectTypeEnum[],
    dataPointsAmount?: number,
    requirements?: TAskIgorRequirement[]
  ) => void;
  documentsSelected?: string[];
};

export const AskIgorLineChats: FC<TAskIgorLineChatsProps> = ({
  object,
  selectedMenuItem,
  updateSelectedMenuItem,
  isGeneratingText,
  defaultInput,
  onSubmit,
  documentsSelected,
}) => {
  // State
  const [askIgorRequirements, setAskIgorRequirements] = useState<
    TAskIgorRequirement[]
  >([]);
  const [dataPointsAmount, setDataPointsAmount] =
    useState<number | undefined>(10);
  const [documentTypes, setDocumentTypes] = useState<ObjectTypeEnum[]>([]);

  // Memo
  const isUserTableLineChatSubmitDisabled = useMemo((): boolean => {
    // check if two requirement texts are the same in askIgorRequirements
    let doesContainDuplicates = false;
    // loop through askIgorRequirements
    askIgorRequirements.forEach((requirementA: TAskIgorRequirement) => {
      // loop through askIgorRequirements
      askIgorRequirements.forEach((requirementB: TAskIgorRequirement) => {
        // if requirement text is the same as requirement2 text
        if (
          requirementA.id !== requirementB.id &&
          requirementA.text === requirementB.text
        ) {
          // set isSameRequirement to true
          doesContainDuplicates = true;
        }
      });
    });

    // if selectedMenuItem is Table and askIgorRequirements is empty or every requirement is not checked or every requirement text is empty or isSameRequirement is true
    if (
      selectedMenuItem === AskIgorMenuItemEnum.Table &&
      (!askIgorRequirements ||
        askIgorRequirements.length === 0 ||
        askIgorRequirements.every(
          (requirement: TAskIgorRequirement) => !requirement.isChecked
        ) ||
        askIgorRequirements.every(
          (requirement: TAskIgorRequirement) => !requirement.text
        ) ||
        doesContainDuplicates)
    ) {
      // return true
      return true;
    }

    // otherwise, return false
    return false;
  }, [askIgorRequirements, selectedMenuItem]);

  const lineChats = useMemo((): TLineChat[] => {
    // trim object name
    const trimmedObjectName = object.name?.trim();

    // define line chats
    const defaultLineChats: TLineChat[] = [
      {
        text: "Hi there! Where can I help you with?",
        isAskAI: true,
        id: window.crypto.randomUUID(),
      },
      {
        id: window.crypto.randomUUID(),
        isAskAI: false,
        hideBorderBottom: true,
        options: [
          {
            text: "I have a question for you",
            onClick: () => {
              updateSelectedMenuItem(AskIgorMenuItemEnum.QuestionAndAnswer);
            },
          },
          {
            text: "Write a report from the linked documents",
            onClick: () => {
              updateSelectedMenuItem(AskIgorMenuItemEnum.GenerateReport);
            },
          },
          {
            text: `Write a general description about "${trimmedObjectName}"`,
            onClick: () => {
              updateSelectedMenuItem(AskIgorMenuItemEnum.GeneralDescription);
            },
          },
          {
            text: "Write a section about...",
            onClick: () => {
              updateSelectedMenuItem(AskIgorMenuItemEnum.WriteSection);
            },
          },
          {
            text: "Extract detail information from the linked documents",
            onClick: () => {
              updateSelectedMenuItem(AskIgorMenuItemEnum.Table);
            },
          },
          {
            text: `Write an executive summary about "${trimmedObjectName}"`,
            onClick: () => {
              updateSelectedMenuItem(AskIgorMenuItemEnum.ExecutiveSummary);
            },
          },
        ],
      },
    ];
    const questionAndAnswerLineChats: TLineChat[] = [
      {
        text: "Ask me any question and I'll try to answer based on your sources.",
        isAskAI: true,
        id: window.crypto.randomUUID(),
        children: (
          <AskIgorSettings
            settings={{
              input: true,
              documents: true,
              highlights: true,
              info: false,
              documentsSelected,
            }}
            onSettingsChange={(
              currDocumentTypes: ObjectTypeEnum[],
              currDataPointsAmount?: number
            ) => {
              setDataPointsAmount(currDataPointsAmount);
              setDocumentTypes([...currDocumentTypes]);
            }}
          />
        ),
      },
      {
        id: window.crypto.randomUUID(),
        isAskAI: false,
        inputPlaceholder: "Enter your question here.",
        isSubmitShown: true,
        isInputShown: true,
        disableSubmitWhenInputEmpty: true,
        inputValue: defaultInput ?? "",
      },
    ];
    const generateReportLineChats: TLineChat[] = [
      {
        id: window.crypto.randomUUID(),
        isAskAI: true,
        text: "I will write a report with an Introduction, Methods, Results, and Conclusion based on your sources.",
        children: (
          <AskIgorSettings
            settings={{ documents: true, info: false, documentsSelected }}
            onSettingsChange={(
              currDocumentTypes: ObjectTypeEnum[],
              currDataPointsAmount?: number
            ) => {
              setDataPointsAmount(currDataPointsAmount);
              setDocumentTypes([...currDocumentTypes]);
            }}
          />
        ),
      },
      {
        id: window.crypto.randomUUID(),
        isAskAI: false,
        isSubmitShown: true,
      },
    ];
    const generalDescriptionLineChats: TLineChat[] = [
      {
        id: window.crypto.randomUUID(),
        isAskAI: true,
        text: `I will write a general description about "${trimmedObjectName}". Do you want me to write it based on your linked sources or general knowledge?`,
      },
      {
        id: window.crypto.randomUUID(),
        isAskAI: false,
        options: [
          {
            text: "Use connected documents and their highlights",
            icon: faFile,
            onClick: () => {
              updateSelectedMenuItem(
                AskIgorMenuItemEnum.GeneralDescriptionUsingLinks
              );
            },
          },
          {
            text: "Use your general knowledge",
            icon: faGlobe,
            onClick: () => {
              updateSelectedMenuItem(
                AskIgorMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge
              );
            },
          },
        ],
      },
    ];
    const generalDescriptionUsingLinksLineChats: TLineChat[] = [
      {
        id: window.crypto.randomUUID(),
        isAskAI: true,
        text: `I will write a general description about "${trimmedObjectName}" using your sources.`,
        children: (
          <AskIgorSettings
            settings={{
              documents: true,
              content: "Yes",
              highlights: true,
              info: false,
              documentsSelected,
            }}
            onSettingsChange={(
              currDocumentTypes: ObjectTypeEnum[],
              currDataPointsAmount?: number
            ) => {
              setDataPointsAmount(currDataPointsAmount);
              setDocumentTypes([...currDocumentTypes]);
            }}
          />
        ),
      },
      {
        id: window.crypto.randomUUID(),
        isAskAI: false,
        isSubmitShown: true,
      },
    ];
    const generalDescriptionUsingGeneralKnowledgeLineChats: TLineChat[] = [
      {
        id: window.crypto.randomUUID(),
        isAskAI: true,
        text: `I will write a general description about "${trimmedObjectName}" using general knowledge.`,
        children: (
          <AskIgorSettings
            settings={{ info: true, documentsSelected }}
            onSettingsChange={() => {
              setDataPointsAmount(undefined);
              setDocumentTypes([]);
            }}
          />
        ),
      },
      {
        id: window.crypto.randomUUID(),
        isAskAI: false,
        isSubmitShown: true,
      },
    ];
    const writeSectionLineChats: TLineChat[] = [
      {
        id: window.crypto.randomUUID(),
        isAskAI: true,
        text: "Give me a header and I will write the section.",
        children: (
          <AskIgorSettings
            settings={{
              input: true,
              documents: true,
              highlights: true,
              info: false,
              documentsSelected,
            }}
            onSettingsChange={(
              currDocumentTypes: ObjectTypeEnum[],
              currDataPointsAmount?: number
            ) => {
              setDataPointsAmount(currDataPointsAmount);
              setDocumentTypes([...currDocumentTypes]);
            }}
          />
        ),
      },
      {
        id: window.crypto.randomUUID(),
        isAskAI: false,
        inputPlaceholder: "Enter the title of the section here.",
        isSubmitShown: true,
        isInputShown: true,
        disableSubmitWhenInputEmpty: true,
        inputValue: defaultInput ?? "",
      },
    ];
    const tableLineChats: TLineChat[] = [
      {
        id: window.crypto.randomUUID(),
        isAskAI: true,
        text: "I will extract detail information from your sources. Please provide me the details you are looking for. Based on the selected object, I will try to add some you may be interested in.",
        children: (
          <AskIgorSettings
            settings={{
              documents: false,
              highlights: false,
              info: false,
              documentsSelected,
            }}
            onSettingsChange={(
              currDocumentTypes: ObjectTypeEnum[],
              currDataPointsAmount?: number
            ) => {
              setDataPointsAmount(undefined);
              setDocumentTypes([...currDocumentTypes]);
            }}
          />
        ),
      },
      {
        id: window.crypto.randomUUID(),
        isAskAI: false,
        children: (
          <AskIgorRequirementsLineChat
            object={object}
            onRequirementsChange={(requirements: TAskIgorRequirement[]) => {
              setAskIgorRequirements([...requirements]);
            }}
          />
        ),
        isSubmitShown: true,
      },
    ];
    const executiveSummaryLineChats: TLineChat[] = [
      {
        id: window.crypto.randomUUID(),
        isAskAI: true,
        text: `I will write an executive summary about "${trimmedObjectName}" based on the content of the page, and your sources.`,
        children: (
          <AskIgorSettings
            settings={{
              documents: true,
              content: "Yes",
              highlights: true,
              info: false,
              documentsSelected,
            }}
            onSettingsChange={(
              currDocumentTypes: ObjectTypeEnum[],
              currDataPointsAmount?: number
            ) => {
              setDataPointsAmount(currDataPointsAmount);
              setDocumentTypes([...currDocumentTypes]);
            }}
          />
        ),
      },
      {
        id: window.crypto.randomUUID(),
        isAskAI: false,
        isSubmitShown: true,
      },
    ];

    switch (selectedMenuItem) {
      case AskIgorMenuItemEnum.QuestionAndAnswer:
        return questionAndAnswerLineChats;
      case AskIgorMenuItemEnum.GeneralDescription:
        return generalDescriptionLineChats;
      case AskIgorMenuItemEnum.GeneralDescriptionUsingLinks:
        return generalDescriptionUsingLinksLineChats;
      case AskIgorMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge:
        return generalDescriptionUsingGeneralKnowledgeLineChats;
      case AskIgorMenuItemEnum.ExecutiveSummary:
        return executiveSummaryLineChats;
      case AskIgorMenuItemEnum.Table:
        return tableLineChats;
      case AskIgorMenuItemEnum.WriteSection:
        return writeSectionLineChats;
      case AskIgorMenuItemEnum.GenerateReport:
        return generateReportLineChats;
      default:
        return defaultLineChats;
    }
  }, [
    object,
    defaultInput,
    documentsSelected,
    selectedMenuItem,
    updateSelectedMenuItem,
  ]);

  return (
    <div className={styles.lineChats}>
      {lineChats.map((lineChat: TLineChat) => {
        return (
          <TextLineChat
            lineChat={lineChat}
            isGeneratingText={isGeneratingText}
            isSubmitDisabled={isUserTableLineChatSubmitDisabled}
            onSubmit={(text: string) => {
              onSubmit(
                text,
                documentTypes,
                dataPointsAmount,
                askIgorRequirements
              );
            }}
            key={lineChat.id}
          />
        );
      })}
    </div>
  );
};
